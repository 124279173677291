import React from "react"
import { graphql } from "gatsby"
import { ViewsNewsListQuery } from "../../types/graphql-types"
import {
  AppBar,
  Breadcrumb,
  Footer,
  Layout,
  SEO,
  SnsShare,
} from "../components"
import { NewsItem, List } from "../views/news"

type Props = {
  data: ViewsNewsListQuery
}

const Component: React.FC<Props> = (props: Props) => {
  const title: string = "銃・射撃・狩猟ニュース"
  const slug: string = "news"

  const incidentNewsList: Array<NewsItem> = props.data.incidentNews.edges.map(
    v => {
      return {
        title: v.node.frontmatter.title,
        published: v.node.frontmatter.published,
        slug: v.node.fields.slug,
      }
    }
  )

  const lawNewsList: Array<NewsItem> = props.data.lawNews.edges.map(v => {
    return {
      title: v.node.frontmatter.title,
      published: v.node.frontmatter.published,
      slug: v.node.fields.slug,
    }
  })

  return (
    <Layout>
      <SEO title={title} slug={slug} />
      <SnsShare title={title} slug={slug} />
      <AppBar />
      <Breadcrumb
        showNav={true}
        itemList={[
          { name: "トップ", slug: "" },
          { name: title, slug: slug },
        ]}
      />
      <List title="事件・事故" newsList={incidentNewsList} />
      <List title="法令・制度" newsList={lawNewsList} />
      <Footer slug="/" copyright={{ publishDate: new Date() }} />
    </Layout>
  )
}

export default Component

export const query = graphql`
  query ViewsNewsList {
    incidentNews: allMarkdownRemark(
      sort: { fields: [frontmatter___published], order: DESC }
      filter: {
        frontmatter: { tags: { in: ["事件・事故"] }, published: { ne: "null" } }
      }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            published(formatString: "YYYY年M月D日")
            title
          }
          id
          excerpt(format: PLAIN)
        }
      }
    }
    lawNews: allMarkdownRemark(
      sort: { fields: [frontmatter___published], order: DESC }
      filter: {
        frontmatter: { tags: { in: ["法令・制度"] }, published: { ne: "null" } }
      }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            published(formatString: "YYYY年M月D日")
            title
          }
          id
          excerpt(format: PLAIN)
        }
      }
    }
  }
`
